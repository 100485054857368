export type HolidayDate = { d: number, m: number }

const fixedHolidays: HolidayDate[] = [
  { d: 1, m: 1 },
  { d: 1, m: 5 },
  { d: 8, m: 5 },
  { d: 5, m: 7 },
  { d: 6, m: 7 },
  { d: 28, m: 9 },
  { d: 28, m: 10 },
  { d: 17, m: 11 },
  { d: 24, m: 12 },
  { d: 25, m: 12 },
  { d: 26, m: 12 },
]

function calculateEaster(year: number): HolidayDate {
  const f = Math.floor
  const G = year % 19
  const C = f(year / 100)
  const H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30
  const I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11))
  const J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7
  const L = I - J
  const month = 3 + f((L + 40) / 44)
  const day = L + 28 - 31 * f(month / 4)

  return { d: day, m: month }
}

export function useCzechHolidays(year: number) {
  const holidays = ref<HolidayDate[]>([...fixedHolidays])

  const easterSunday = calculateEaster(year)
  const easterFriday = new Date(year, easterSunday.m - 1, easterSunday.d - 2)
  const easterMonday = new Date(year, easterSunday.m - 1, easterSunday.d + 1)

  holidays.value.push({ d: easterFriday.getDate(), m: easterFriday.getMonth() + 1 })
  holidays.value.push({ d: easterMonday.getDate(), m: easterMonday.getMonth() + 1 })

  holidays.value.sort((a, b) => a.m - b.m || a.d - b.d)

  return holidays
}
