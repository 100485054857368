import dayjs from 'dayjs'
import 'dayjs/locale/cs'
import isBetween from 'dayjs/plugin/isBetween'
import localeData from 'dayjs/plugin/localeData'

dayjs.extend(localeData)
dayjs.extend(isBetween)
dayjs.locale('cs')

export function useOpeningHours(validatingDate: Date = new Date()): boolean {
  const czechHolidays = useCzechHolidays(validatingDate.getFullYear())

  const isCzechHoliday = (date: Date): boolean => unref(czechHolidays).some(holiday => holiday.d === date.getDate() && holiday.m === date.getMonth() + 1)

  const isWorkingTime = (date: Date): boolean => {
    const start = dayjs(date).hour(9)
    const end = dayjs(date).hour(17)

    return dayjs(date).isBetween(start, end, 'minute', '[)')
  }

  const isWeekday = (date: Date): boolean => {
    const day = dayjs(date).day()
    return day >= 1 && day <= 5
  }

  const isWorkingDay = (date: Date): boolean => isWeekday(date) && !isCzechHoliday(date) && isWorkingTime(date)

  return isWorkingDay(validatingDate)
}
